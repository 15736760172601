import moment from 'moment';
import { I18n } from 'i18n';

export function DateData(data, props) {
  const { year: selectedYear, week: SelectWeek, hideWeek, hideDay, hideMonth } = props;

  let years = data
      .reduce((memo, date) => {
        if (!memo.includes(date.year)) memo.push(date.year);
        return memo;
      }, [])
      .sort()
      .reverse()
      .map((value) => ({ value })),
    weeks = [],
    days = [],
    months = [];

  if (selectedYear && !hideMonth) {
    const labelMount = moment.months();
    months = data
      .reduce((memo, date) => {
        if (date.year !== selectedYear) return memo;
        if (!memo.includes(date.month)) memo.push(date.month);
        return memo;
      }, [])
      // eslint-disable-next-line array-callback-return
      .sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
      })
      .reverse()
      .map((value) => ({ value, name: I18n(labelMount[value], true) }));
  }

  if (selectedYear && !hideWeek) {
    weeks = data
      .reduce((memo, date) => {
        if (date.year !== selectedYear) return memo;
        if (!memo.includes(date.week)) memo.push(date.week);
        return memo;
      }, [])
      // eslint-disable-next-line array-callback-return
      .sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
      })
      .reverse()
      .map((value) => ({ value }));
  }

  if (SelectWeek && !hideDay) {
    const labelDays = weekArray();
    days = data
      .reduce((memo, date) => {
        if (date.week !== SelectWeek) return memo;
        if (!memo.includes(date.day)) memo.push(date.day);
        return memo;
      }, [])
      // eslint-disable-next-line array-callback-return
      .sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
      })
      .reverse()
      .map((value) => ({ value, name: I18n(labelDays[value - 1], true) }));
  }
  return { years, weeks, days, months };
}

export function weekArray() {
  return Array.apply(null, Array(7)).map(function (_, i) {
    return moment(i, 'e')
      .startOf('week')
      .isoWeekday(i + 1)
      .format('dddd');
  });
}

export function timeToMinutes(time) {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};