import React from 'react';
import { I18n } from 'i18n';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { Container, Content } from 'components';
import { Table as TableUI } from 'antd';
import { Filters } from './filters';
import { useOverviewWeather } from 'apollo/weather';
import { timeToMinutes } from 'utils';

const stateName = 'environment.weather.log';

export function Log() {
  const {
    environment: {
      weather: { log },
    },
  } = useSelector(({ state }) => state);
  const { data, loading } = useOverviewWeather(log);
  return (
    <Container>
      <Filters stateName={stateName} />
      <Content>
        {loading && !data.length ? (
          <Spin spinning={loading} />
        ) : (
          <TableUI
            dataSource={data}
            bordered
            scroll={ { y: 550 } }
            tableLayout="auto"
            size="small"
            pagination={false}
            rowKey={(data) => data._id + data.day + data.hours}
            columns={columns(data)}
          />
        )}
      </Content>
    </Container>
  );
}

function columns(data = []) {
  let columns = [];
  columns.push({
    title: I18n('hour', true),
    key: 'hours_apo',
    dataIndex: 'hours_apo',
    // filters: findFilters(data, 'hours_apo'),
    // onFilter: (value, record) => record['hours_ori'] === value,
    sorter: (a, b) => timeToMinutes(a.hours_apo) - timeToMinutes(b.hours_apo),
  });
  columns.push({
    title: I18n('temperature', true),
    key: 'temperature',
    dataIndex: 'temperature',
    // filters: findFilters(data, 'temperature'),
    // onFilter: (value, record) => record['temperature'] === value,
    sorter: (a, b) => a.temperature - b.temperature,
  });

  columns.push({
    title: I18n('humidity', true),
    key: 'humidity',
    dataIndex: 'humidity',
    // filters: findFilters(data, 'humidity'),
    // onFilter: (value, record) => record['humidity'] === value,
    sorter: (a, b) => a.humidity - b.humidity,
  });

  return columns;
}

// function findFilters(data, key) {
//   let out = data.map((item) => item[key]);
//   out = [...new Set(out)].sort();
//   return out.map((value) => ({ text: value, value }));
// }
